<template>
  <div>
      <div 
      class="allRowContainer"
      >
      <div v-if="valueRE.month === this.valueMes" class="row align-items-center" id="rowContainerOne" style="height: 45px; ">
              <div  class="col-md-4" id="linha-item">
                  <p class="numeroPedido mb-0">Lucro Bruto</p>
              </div>
              <div class="col-md-8" id="linha-item">
                  <p class="precoPedido mb-0">{{ valueRE.grossProfit }}</p>
                  <p></p>
              </div>
          </div>
           <div v-if="valueRE.month === this.valueMes" class="row align-items-center" id="rowContainerTwo" style="height: 45px; ">
              <div class="col-md-4" id="linha-item">
                  <p class="numeroPedido mb-0">Despesa</p>
              </div>
              <div class="col-md-8" id="linha-item">
                  <p class="precoPedido mb-0">{{ valueRE.expense }}</p>
                  <p></p>
              </div>
          </div>  
      </div>
  </div>
</template>

<script>
import totalDre from "./totalDre.vue"
export default {
  components: {
    totalDre,
  },

  props:["valueRE", "valueMes"],

  
};

</script>

<style scoped>

#rowContainerOne{
  background-color: #FFFF;
  border-radius: 7px;
  border: 1px rgb(135, 254, 143) solid;
  margin-bottom: 3px;
  margin-left: 0px;
  margin-right: 0px;
}
#rowContainerTwo{
  background-color: #FFFF;
  border-radius: 7px;
  border: 1px  rgb(246, 182, 182) solid;
  margin-bottom: 3px;
  margin-left: 0px;
  margin-right: 0px;
}

#linha-item{
  height: 40%;
  display: flex;
  align-items: center;
}

.numeroPedido {
  font-size: 16px;
  font-weight: 400;
  color: #357eb8;
}
.allRowContainer{
  width: 100%;
  max-height: 285px;
  
  padding-right: 7px;
}
.precoPedido {
  font-size: 16px;
  font-weight: 400;
  color: #357eb8;
}
#precoServico{
  font-size: 16px;
  font-weight: 400;
}
.col-md-8{
    padding-left: 51%;
}
</style>

