<template>
  <div id="indicadores" class="pt-5">
    <div class="welcomeMobile" v-if="this.sizeWindow <= 767">
        <router-link to="/" class="imgWelCont">
          <img src="../assets/image/iconesMobile/grayArrow.svg" class="grayArrow">
        </router-link>
        <div class="txtWelCont">
          <p>Indicadores</p>
        </div>
    </div>
    <div class="container">
      <div class="col-md-12">
        <div class="row align-items-center">
          <div class="col-md-2">
            <p class="my-3 titulo_dash"  v-if="this.sizeWindow > 767">Indicadores</p>
          </div>
          <div class="col-md-5 ml-auto">
            <div class="row" id="selectInfoCont">
              <div :class="sizeWindow <= 767 ? 'col-md-6 text-center' : 'col-md-6 text-right'" v-for="(item,index) in tab" :key="index" id="selectInfo">
                <a
                  href="#"
                  class="tab_a my-3"
                  @click.prevent="trocaCP(item.cp)"
                  :class="`${item.cp == cp ? 'active' : ''}`"
                >{{item.name}}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="col-md-10 mx-auto" id="backBox">
            <component :is="cp" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import performance from "@/components/indicadores/performance";
import dre from "@/components/indicadores/dre";
export default {
  components: {
    performance,
    dre
  },
  data() {
    return {
      cp: "performance",
      tab: [
        { name: "PERFORMANCE DA UNIDADE", cp: "performance" },
        { name: "RESULTADOS DO EXERCÍCIO (DRE)", cp: "dre" }
      ],
      sizeWindow: 0,
    };
  },
  mounted() {
    this.sizeWindow = $(window).width();
  },
  methods: {
    trocaCP(val) {
      this.cp = val;
    }
  }
};
</script>

<style scoped>
#indicadores {
  background-color: #2474b2;
}
.tab_a {
  color: #175d93;
  font-weight: 700;
}
.active {
  color: #ffffff;
}
.col-md-10{
  margin-left: 0!important;
}
#backBox{
  margin: 0px!important;
  max-width: 100%!important;
}
@media screen and (max-width:767px){
  
  .welcomeMobile{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      background: #217ABF;
      height: 40px;
      margin-bottom: 3%;
    }
    .imgWelCont{
      width: 44%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .txtWelCont{
      width: 77%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .txtWelCont p{
      margin-bottom: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
    }
    .imgWelCont img{
      padding-left: 7%;
      width: 29%;
    }
    #indicadores{
      padding-top: 0!important;
    }
    #selectInfoCont{
      justify-content: center;
      align-items: center;
    }
    #selectInfo{
      width: 50%;
    }
    
  }
</style>