<template>
  <div class="rowFixed">
      <div 
      class="allRowContainer"
      >
          <div class="row align-items-center" id="rowContainer" style="height: 45px; ">
              <div class="col-md-4" id="linha-item">
                  <p class="numeroPedido mb-0">{{valueRE.month}}</p>
              </div>
              <div class="col-md-8" id="linha-item">
                  <p  v-if="this.valueDEF !== 'Despesa'" class="precoPedido mb-0">{{ valueRE.grossProfit }}</p>
                  <p></p>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data() {
    return {
        allInformations: [],
        bodyContent: [],
        id: [],

      };
  },
  props:["valueRE", "valueDEF"],
  
  
};

</script>

<style scoped>

#rowContainer{
  background-color: #FFFF;
  border-radius: 7px;
  border: 1px #d4d6d8 solid;
  margin-bottom: 3px;
  margin-left: 0px;
  margin-right: 0px;
}

#linha-item{
  height: 40%;
  display: flex;
  align-items: center;
}

.numeroPedido {
  font-size: 16px;
  font-weight: 400;
  color: #357eb8;
}
.allRowContainer{
  width: 100%;
  max-height: 285px;
  
  padding-right: 7px;
}
.precoPedido {
  font-size: 16px;
  font-weight: 400;
  color: #357eb8;
}
#precoServico{
  font-size: 16px;
  font-weight: 400;
}
.col-md-8{
    padding-left: 51%;
}
@media screen and (max-width:767px){
  #rowContainer{
    display: flex;
    height: 45px;
    flex-direction: row;
    flex-wrap: unset;
  }
  #linha-item{
    width: 50%;
    padding-left: 15px!important;
  }

}
</style>

