<template>
  <div class="pb-5">
    <div style="height:250px;" v-if="load">
      <loader color="#e0a638" size="100" />
    </div>
    <transition name="vertical">
      <div class="d-flex justify-content-end" v-if="!load">
        <a
          href="#"
          class="tab_a my-4 mx-1"
          v-for="(item, index) in tab"
          :key="index"
          :class="`${item.type == type ? 'active' : ''}`"
          @click.prevent="getTrimestre(item.type)"
          >{{ item.name }}</a
        >
      </div>
    </transition>
    <transition name="vertical">
      <div class="row" v-if="!load">
        <indicadores
          v-for="(item, index) in indicadores"
          :key="index + 1"
          :item="item"
        />
      </div>
    </transition>
    <transition name="vertical">
      <grafico
        class="col-md-12"
        v-if="!load && grafico != ''"
        :grafico.sync="grafico"
      />
    </transition>
  </div>
</template>

<script>
import indicadores from "./_Indicadores";
import grafico from "./performance/grafico";

import loader from "@/components/utils/_Load";
export default {
  components: { indicadores, grafico, loader },
  data() {
    return {
      type: "mes",
      indicadores: [],
      grafico: [],
      tab: [
        { name: this.getMes(), type: "mes" },
        { name: "Trimestre", type: "Trimestre" },
        { name: "Acumulado YTD", type: "acumulado" }
      ],
      load: false
    };
  },
  mounted() {
    this.getTrimestre("mes");
  },
  methods: {
    getTrimestre(tipo) {
      this.load = true;
      this.type = tipo;
      this.grafico = [];
      this.$store
        .dispatch("getIndicadores", tipo)
        .then(resolve => {
          this.load = false;
          this.indicadores = resolve.indicadores;

          this.grafico.push(
            {
              name: "Receita Bruta",
              item: resolve.grafico.grossProfit,
              id: 1
            },
            { name: "Receita Líquida", item: resolve.grafico.netProfit, id: 2 },
            { name: "Despesas", item: resolve.grafico.expense, id: 3 },
            { name: "Metas", item: resolve.grafico.goal, id: 4 },
            { name: "Média/Dia", item: resolve.grafico.dailyProfit, id: 5 },
            { name: "Ticket Médio", item: resolve.grafico.ticketAverage, id: 6 }
          );
        })
        .catch(reject => {
          this.load = false;
        });
    },
    getMes() {
      var date = new Date();
      var mes = date.getMonth();
      var meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ];
      return meses[mes];
    }
  },
  computed: {}
};
</script>

<style scoped>
.tab_a {
  color: #175d93;
  font-weight: 700;
}
.active {
  color: #e0a638;
}
</style>
