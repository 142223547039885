<template>
  <!-- <div class="row"> -->
  <div class="col-md-4" id="whiteBack">
    <p class="my-0 titulo_dash topo" id="monthindicator">{{result.month}}</p>
    <div class="box d-flex w-100 align-items-center px-2 my-3" >
      <p class="mb-0 caixaIndicador">{{result.grossProfit}}  </p>
      <div class="buttonContainer">       
        <a @click="$emit('showModalDetails', 'Receita Bruta', result.month, year, result.grossProfit, result.id, 'greenPlusIcon.svg')"><img src="@/assets/image/icones/dreIcon.svg"></a>
      </div>
    </div>
    <div class="box d-flex w-100 align-items-center px-2 my-3" id="mid">
      <p class="mb-0 caixaIndicador">{{result.expense}}</p>
      <div class="buttonContainer">       
        <a @click="$emit('showModalDetails', 'Despesa', result.month, year, result.expense, result.id, 'menorRedIcon.svg')"><img src="@/assets/image/icones/dreIcon.svg"></a>
      </div>
    </div>
    <div class="box d-flex w-100 align-items-center px-2 my-3"  id="mid">
      <p class="mb-0 caixaIndicador">{{result.netProfit}}</p>
      <div class="buttonContainer">       
        <a @click="$emit('showModalDetails', 'Lucro Líquido', result.month, year, result.netProfit, result.id, 'blueEqualIcon.svg')"><img src="@/assets/image/icones/dreIcon.svg"></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["result", "year"],
};
</script>

<style scoped>
#monthindicator{
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonContainer{
  display:flex;
  align-items: center;
  justify-content: center;
}
.topo {
  padding-top: 6px;
  font-size: 12px !important;
  color: rgb(83, 83, 83);
}
.box {
  height: 60px;
  background-color: #e5e3e3;
  margin-top: 5px!important;
  border-radius: 8px; 
  margin-bottom: 8px!important;
}
.caixaIndicador {
  min-width: 85%;
  font-size: 17px;
  font-weight: 700;
  color: rgb(65, 64, 64);
}
#whiteBack{
  margin-top: 16px;
  background-color: #ffff;
  height: 250px;
  z-index: 1;
}


</style>