<template>
    <div>
        <div class="infoValueCont">
          <div class="buttonContainer">  
            <p class="my-0 titulo_dash topo" id="monthindicator">{{result.month}}</p>     
            <a @click="$emit('showModalDetails', 'Lucro Líquido', result.month, year, result.netProfit, result.id, 'blueEqualIcon.svg')"><img src="@/assets/image/icones/dreIcon.svg"></a>
          </div>
          <div class="box d-flex w-100 align-items-center px-2 my-3" >
            <p class="mb-0 caixaIndicador">{{result.netProfit}}</p>
          </div>
        </div>
    </div>
</template>
<script>
export default {
  props: ["result", "year"],
  methods:{    
}
}
</script>

<style scoped>
#monthindicator{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12%;
}
.buttonContainer{
  display:flex;
  align-items: center;
  justify-content: center;
}
.topo {
  padding-top: 6px;
  font-size: 12px !important;
  color: rgb(83, 83, 83);
}

.caixaIndicador {
  min-width: 85%;
  font-size: 17px;
  font-weight: 700;
  color: rgb(65, 64, 64);
}
.infoValueCont{
  width: 96%;
  margin-right: 31px;
  height: 80px;
  background-color: #e5e3e3;
  margin-top: 5px!important;
  border-radius: 8px; 
  margin-bottom: 8px!important;
}
</style>