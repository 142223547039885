import { render, staticRenderFns } from "./totalDre.vue?vue&type=template&id=4f2ba945&scoped=true&"
import script from "./totalDre.vue?vue&type=script&lang=js&"
export * from "./totalDre.vue?vue&type=script&lang=js&"
import style0 from "./totalDre.vue?vue&type=style&index=0&id=4f2ba945&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f2ba945",
  null
  
)

export default component.exports