<template>
    <div class="allRowContainerTotal">
        <div class="row d-flex align-items-center" id="rowContainerTotal" style="height: 45px; ">
            <div class="col-md-4 col-8" id="linha-itemTotal">
                <p class="numeroPedidoTotal mb-0"><strong>Total {{totalTitle}}</strong></p>
            </div>
            <div class="col-md-8 col" id="linha-itemTotalTwo">
                <p class="precoPedidoTotal mb-0"><strong>{{totalPrice}}</strong></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["totalTitle", "totalPrice"],
    data(){
        return{
            dreObj: {},
        }
    },

}
</script>

<style scoped>
#rowContainerTotal{
  background-color: #FFFF;
  border-radius: 7px;
  border:none;
  margin-bottom: 3px;
  margin-left: 0px;
  margin-right: 10px;

}
.numeroPedidoTotal {
  font-size: 16px;
  font-weight: 400;
  color: #357eb8;
}
.precoPedidoTotal {
  font-size: 16px;
  font-weight: 400;
  color: #357eb8;
}
#linha-itemTotal{
  height: 40%;
  display: flex;
  align-items: center;
}
#linha-itemTotalTwo{
  height: 40%;
  display: flex;
  align-items: center;
}
.col-md-8{
    padding-left: 50%;
}
p{
     color: rgb(65, 64, 64)!important;
}
@media screen and (max-width:767px){
    #linha-itemTotal{
        width: 65%;
        padding-left: 15px!important;
    }
    #linha-itemTotalTwo{
        width: 65%;
        padding-left: 0!important;
    }
    #rowContainerTotal{
        display: flex;
        flex-direction: row;
    }
}
</style>
